import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid";

import AedasLoading from "../../Shared/AedasLoading/AedasLoading";
import DataGridEditabled from "../../Shared/DataGridEditabled/DataGridEditabled";
import CustomModal from "../../Shared/ModalCustom/CustomModal";
import { NewPartnerModal } from "./NewPartnerModal/NewPartnerModal";

import "./Partners.css";
import { useFetch, useIsLoading } from "../../../Hooks/useFetch";
import {
  getAllRols,
  getAllUsers,
  reAsingProms,
  updateUser,
} from "../../../service/UserService/UserService";
import { getAllPromotions } from "../../../service/DW_Promotions/DW_Promotions_Service";
import {
  getDBPromotions,
  getPromotionsByIdUser,
} from "../../../service/PromotionServices/PromotionServices";
import { useFormik } from "formik";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import {
  getFreeDT,
  getUserDT,
  updateUserDT,
} from "../../../service/DelegationServices/DelegationServices";
import { CalculateSharp } from "@mui/icons-material";

export const Partners = () => {
  const [partners, isFechingPartners, updatePartners] = useFetch(getAllUsers);

  const [rols, rolsIsfeching, updateRols] = useFetch(getAllRols);
  const [promotions, promotionsIsFeching, updatePromotions] =
    useFetch(getDBPromotions);

  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [selectedRol, setSelectedRol] = useState<any>(null);
  const isLoadingFecth = useIsLoading([
    rolsIsfeching,
    isFechingPartners,
    promotionsIsFeching,
  ]);
  const [openEditModalShow, setOpenEditModalShow] = useState<boolean>(false);
  const [openModalShow, setOpenModalShow] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    if (selectedRow && selectedRow.state !== 1) {
      setOpenModalShow(true);
    }
  }, [selectedRow]);
  useEffect(() => {
    if (!openModalShow) {
      setSelectedRol(false);
      updatePartners();
    }
  }, [openModalShow]);
  if (isLoadingFecth) {
    return (
      <>
        <AedasLoading></AedasLoading>
      </>
    );
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 9,
      hide: true,
      editable: false,
    },

    {
      field: "display_name",
      headerName: "Nombre y apellido",
      type: "string",
      width: 200,
      editable: false,
      flex: 1,
    },

    {
      field: "id_rol",
      headerName: "Rol",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        const rol: any = rols.find((r: any) => r.id === params.value);
        return <div>{rol ? rol.name : "Sin Rol asignado"}</div>;
      },
      width: 200,
      editable: true,
      flex: 1,
    },

    {
      field: "email",
      type: "string",
      headerName: "Email",
      editable: false,
      width: 200,
      flex: 1,
    },
    {
      field: "state",
      type: "string",
      headerName: "Estado",
      editable: false,
      width: 200,
      flex: 1,
      renderCell: (params: GridRenderEditCellParams) => {
        if (params.value === 2) {
          return (
            <div
              style={{
                padding: "4px",
                backgroundColor: "var(--secondary-color)",
                color: "white",
                borderRadius: "10px",
                fontWeight: "bold",
              }}
            >
              Token Caducado
            </div>
          );
        } else if (params.value === 1) {
          return (
            <div
              style={{
                padding: "4px",
                backgroundColor: "blue",
                color: "white",
                borderRadius: "10px",
                fontWeight: "bold",
              }}
            >
              Pendiente de registro
            </div>
          );
        }

        const user = partners.find((p: any) => p.id === params.id);
        if (user.active) {
          return (
            <div
              style={{
                padding: "4px",
                backgroundColor: "var(--aedas-green)",
                color: "white",
                borderRadius: "10px",
                fontWeight: "bold",
              }}
            >
              Activado
            </div>
          );
        } else {
          return (
            <div
              style={{
                padding: "4px",
                backgroundColor: "var(--aedas-red)",
                color: "white",
                borderRadius: "10px",
                fontWeight: "bold",
              }}
            >
              Desactivado
            </div>
          );
        }
      },
    },
  ];

  const onCellClick = (e: any) => {
    setSelectedRow(partners.find((p: any) => p.id === e.id));
    console.log("click", e);
  };
  console.log("pro", promotions);
  const handleAdd = (e: any) => {
    setOpenEditModalShow(!openEditModalShow);

    // setSelectedRow(e.row);
    // setOpenEditModalShow(!openEditModalShow);

    const rolRow: any = rols.find((r: any) => r.id === e.row.id_rol);
    // const delegationRow: any = delegation.find(
    //   (d: any) => d.id === e.row.delegacion_provincial
    // );

    // const employeeTagRow: any = employeeTags.filter(
    //   (t: any) => t.id_employee === e.row.id
    // );

    setSelectedRol(rolRow);
    // setSelectedDelegation(delegationRow);
    // setSelectedEmployeeTag(employeeTagRow);
  };

  return (
    <div>
      <h2 className="page-title">Gestión de Colaboradores</h2>
      <CustomModal
        size="xl"
        show={openEditModalShow}
        onChange={() => {
          setOpenEditModalShow(!openEditModalShow);
        }}
        title="Nuevo Usuario"
        body={
          <NewPartnerModal
            selectedRow={selectedRow}
            selectedRol={selectedRol}
            promotions={promotions}
            setOpenEditModalShow={setOpenEditModalShow}
            // selectedDelegation={selectedDelegation}
            // selectedEmployeeTags={selectedEmployeeTag}
            rolOptions={rols}
            // delegationOptions={delegation}
            // tagsOptions={tags}
            // departaments={departaments}
            show={() => setOpenEditModalShow(!openEditModalShow)}
            isLoading={() => setIsLoading(!isLoading)}
          />
        }
      ></CustomModal>
      <CustomModal
        size="xl"
        show={openModalShow}
        onChange={() => {
          setOpenModalShow(!openModalShow);
        }}
        title="Editar Usuario"
        body={
          <EditUserModal
            selectedRow={selectedRow}
            promotions={promotions}
            setOpenModalShow={setOpenModalShow}
            // selectedDelegation={selectedDelegation}
            // selectedEmployeeTags={selectedEmployeeTag}
            rolOptions={rols}
            // delegationOptions={delegation}
            // tagsOptions={tags}
            // departaments={departaments}
            show={() => setOpenModalShow(!openModalShow)}
          />
        }
      ></CustomModal>
      <DataGridEditabled
        showHeader={true}
        rows={partners ?? []}
        columns={columns}
        onCellClick={(event: any) => onCellClick(event)}
        handleAdd={(event: any) => handleAdd(event)}
        singleIcon={true}
        addIcon={true}
      ></DataGridEditabled>
    </div>
  );
};

function EditUserModal(props: any) {
  const [PromosAndWayfindings, isFetchingPromosAndWayfindings] = useFetch(
    getPromotionsByIdUser,
    props.selectedRow.id
  );
  const isLoading = useIsLoading([isFetchingPromosAndWayfindings]);

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  if (props.selectedRow.email.includes("@aedashomes.com")) {
    return (
      <EditUserInternalModal
        {...props}
        PromosAndWayfindings={PromosAndWayfindings}
      >
        {" "}
      </EditUserInternalModal>
    );
  }
  return (
    <EditUserExternalModal
      {...props}
      PromosAndWayfindings={PromosAndWayfindings}
    ></EditUserExternalModal>
  );
}
function EditUserExternalModal(props: any) {
  const [isLoading, setisLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      rol: props.rolOptions.find((r: any) => r.id === props.selectedRow.id_rol),
      active: props.selectedRow.active,
      promotions:
        props.promotions.filter((p: any) =>
          props.PromosAndWayfindings?.promotions.find(
            (pro: any) => pro.id === p.id
          )
        ) ?? [],
    },
    validationSchema: null,
    onSubmit: (values) => {
      setisLoading(true);
      const value = {
        id: props.selectedRow.id,
        display_name: props.selectedRow.display_name,
        email: props.selectedRow.email,
        id_rol: values.rol.id,
        url_img: props.selectedRow.url_img,
        active: values.active,
      };
      updateUser(value)
        .then(() => {
          reAsingProms({
            id_user: props.selectedRow.id,
            promos: values.promotions.map((p: any) => p.id),
          })
            .then(() => {
              setisLoading(false);
              toast.success("Guardado");
              props.setOpenModalShow(false);
            })
            .catch(() => {
              toast.error("Ha habido un error");
              setisLoading(false);
            });
        })
        .catch(() => {
          toast.error("Ha habido un error");
          setisLoading(false);
        });
    },
  });

  return props.selectedRow?.state === 2 ? (
    <div
      style={{ width: "100%", flexDirection: "column", alignItems: "center" }}
      className="d-flex justify-content-center"
    >
      <h3>El token esta caducado</h3>
      <p>
        Pulsa el siguiente botón para reenviar un nuevo email al colaborador y
        pueda completar el registro.
      </p>
      <Button
        type="submit"
        className="btn_submit"
        onClick={() => {
          axios
            .post(
              process.env.REACT_APP_API_BACKEND +
                "/new_user/reToken/?id_user=" +
                props.selectedRow.id,
              {}
            )
            .then(() => {
              toast.success("Se ha enviado el email");
              props.setOpenModalShow(false);
            })
            .catch(() => {
              toast.error("Ha habido un error");
            });
        }}
      >
        Reenviar email de registro
      </Button>
    </div>
  ) : (
    <form onSubmit={formik.handleSubmit}>
      <div className="form_new_user_internal">
        <div style={{ width: "40%" }} className=" p-4">
          <Autocomplete
            id="rol"
            value={formik.values.rol}
            options={props.rolOptions.filter(
              (r: any) => r.id === 3 || r.id === 4
            )}
            onChange={(e, value) => {
              formik.setFieldValue("rol", value);
            }}
            getOptionLabel={(option: any) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField name="rol" {...params} label="Rol" />
            )}
          />
        </div>
        <div style={{ width: "40%" }} className=" p-4">
          <Autocomplete
            id="promotions"
            multiple
            value={formik.values.promotions}
            options={props.promotions}
            onChange={(e, value) => {
              formik.setFieldValue("promotions", value);
            }}
            getOptionLabel={(option: any) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField name="promotions" {...params} label="Promociones" />
            )}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
          className="w-10 p-4"
        >
          <FormControlLabel
            control={
              <Checkbox
                value={formik.values.active}
                // @ts-ignore
                defaultChecked={formik.values.active}
                onChange={formik.handleChange}
              />
            }
            label="Activado"
            id="active"
            name="active"
          />
        </div>
      </div>

      <div style={{ gap: "1rem" }} className="d-flex justify-content-center">
        <Button
          className="btn_submit"
          onClick={() => {
            axios
              .post(
                process.env.REACT_APP_API_BACKEND +
                  "/external_user/recover/?email=" +
                  props.selectedRow.email,
                {}
              )
              .then(() => {
                toast.success("Se ha enviado el email");
                props.setOpenModalShow(false);
              })
              .catch(() => {
                toast.error("Ha habido un error");
              });
          }}
        >
          Resetablecer Contraseña
        </Button>
        <LoadingButton type="submit" className="btn_submit" loading={isLoading}>
          {isLoading ? "" : "Guardar"}
        </LoadingButton>
      </div>
    </form>
  );
}

function EditUserInternalModal(props: any) {
  console.log("porps", props);
  const [isLoading, setisLoading] = useState(false);
  const [dtUser, isFetchingDtUser] = useFetch(getUserDT, props.selectedRow.id);
  const [dt, isFetchingDt] = useFetch(getFreeDT);

  const formik = useFormik({
    initialValues: {
      rol: props.rolOptions.find((r: any) => r.id === props.selectedRow.id_rol),
      active: props.selectedRow.active,
      dt: dtUser,
    },
    validationSchema: null,
    onSubmit: (values) => {
      setisLoading(true);
      const value = {
        id: props.selectedRow.id,
        display_name: props.selectedRow.display_name,
        email: props.selectedRow.email,
        id_rol: values.rol.id,
        url_img: props.selectedRow.url_img,
        active: values.active,
      };
      updateUser(value)
        .then(() => {
          toast.success("Guardado");
          if (values.dt.length > 0) {
            updateUserDT({
              id_user: props.selectedRow.id,
              dts: values.dt.map((d: any) => d.id),
            }).then(() => {
              setisLoading(false);
              props.setOpenModalShow(false);
            });
          } else {
            setisLoading(false);
            props.setOpenModalShow(false);
          }
        })
        .catch(() => {
          setisLoading(false);
          toast.error("Ha habido un error");
        });
    },
  });
  if (isFetchingDtUser || isFetchingDt) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form_new_user_internal">
        <div style={{ width: "80%" }} className=" p-4">
          <Autocomplete
            id="rol"
            value={formik.values.rol}
            options={props.rolOptions}
            onChange={(e, value) => {
              formik.setFieldValue("rol", value);
            }}
            getOptionLabel={(option: any) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField name="rol" {...params} label="Rol" />
            )}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
          className="w-10 p-4"
        >
          <FormControlLabel
            control={
              <Checkbox
                value={formik.values.active}
                // @ts-ignore
                defaultChecked={formik.values.active}
                onChange={formik.handleChange}
              />
            }
            label="Activado"
            id="active"
            name="active"
          />
        </div>
      </div>
      {
        //@ts-ignore
        formik.values?.rol?.id === 2 ? (
          <div className="w-100 p-4">
            <Autocomplete
              id="dt"
              options={dtUser?.concat(dt)}
              onChange={(e, value) => {
                formik.setFieldValue("dt", value);
              }}
              defaultValue={dtUser}
              multiple
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField name="dt" {...params} label="Delegaciones" />
              )}
            />
          </div>
        ) : (
          <></>
        )
      }
      <div className="d-flex justify-content-center">
        <LoadingButton type="submit" className="btn_submit" loading={isLoading}>
          {isLoading ? "" : "Guardar"}
        </LoadingButton>
      </div>
    </form>
  );
}
